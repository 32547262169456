import React, { useEffect, useRef } from "react";
import AppRoute from "./routes/AppRoute";
import ReCAPTCHA from 'react-google-recaptcha';

function App() {
  const recaptchaRef = useRef();

  const triggerRecaptcha = () => {
    // Execute the reCAPTCHA to get the token
    recaptchaRef.current.execute();
  };

  // This function will handle the response from reCAPTCHA
  const handleRecaptchaVerify = (value) => {
    // Send the token to the backend for verification
    fetch(`${process.env.REACT_APP_API_URL}api/Captcha/VerifyRecaptcha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recaptchaToken: value }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log('reCAPTCHA verified successfully!');
        } else {
          console.log('reCAPTCHA verification failed.');
          triggerRecaptcha();
        }
      })
      .catch((error) => {
        console.error('Error verifying reCAPTCHA:', error);
        triggerRecaptcha();
      });
  };

  useEffect(() => {
    
    triggerRecaptcha();
  }, []); 



  return (
    <>
      <AppRoute />

      <div className="footer-background text-center">
        <footer className="container">
          <div className="row">
            <div className="col-5 text-left">
              <ul className="list-inline mt-3 footer-link">
                <li className="list-inline-item">
                  <a href="/terms-condition">Terms & Conditions</a>
                </li>
                <li className="list-inline-item">|</li>
                <li className="list-inline-item">
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-7 text-right">
              <div className="footer-text">Copyright © 2021 Health Cost Labs</div>
            </div>
          </div>
        </footer>
      </div>

  
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
        size="invisible"         
        ref={recaptchaRef}       
        onChange={handleRecaptchaVerify} 
      /> 
    </>
  );
}

export default App;
