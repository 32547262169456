import axios from "axios";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import swal from 'sweetalert2';
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";



class Subscription extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    if (process.env.NODE_ENV !== 'development') {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      }
      );
      document.onkeydown = function (e) {
        if (e.keyCode == 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
          return false;
        }
      }
    }


    //comment for without login appear
    if (!token) {
      this.props.history.push("/login");
    }

    this.state = {
      token: token,
      items: {
        item1: [],
        item2: [],
        item3: [],
        item4: [],
        item5: [],
        item6: [],
        item7: [],
        item8: [],
        item9: [],
        item10: [],
        item11: [],
        item12: [],
      },
      states: [],
      selectedState: 1,
      customSubscription: [],
      selectedStateName: 'Alabama (AL)',
      MaintenanceCharges: null,
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    // this.GetMaintancesCharges = this.GetMaintancesCharges(this)
  }


  alertnotification() {

    swal.fire({
      icon: 'error',
      text: 'Please create account or sign in to purchase.',
      //footer: '<a href=""></a>'
    })
    this.props.history.push("/login");

  }

  refreshToken() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expired = localStorage.getItem("expired");

    if (token !== null && refreshToken !== null && expired !== null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/auth/refreshToken`, {
          token: token,
          refreshToken: refreshToken,
          expiration: expired,
        })
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expired", response.data.expiration);
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            // localStorage.removeItem("token");
            // localStorage.removeItem("email");
            // localStorage.removeItem("userID");
            // localStorage.removeItem("userName");
            // localStorage.removeItem("secret_key");
            // localStorage.removeItem("refreshToken");
            // localStorage.removeItem("expired");
            this.props.history.push("/logout");
          }
        });
    }
  }

  getAllState() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/master/getallstates`, {})
      .then((response) => {
        this.setState({
          states: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          // localStorage.removeItem("token");
          // localStorage.removeItem("email");
          // localStorage.removeItem("userID");
          // localStorage.removeItem("userName");
          // localStorage.removeItem("secret_key");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("expired");
          this.props.history.push("/logout");
        }
      });
  }

  getSubscriptionList() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/master/getsubscription?stateId=1`,
        {
          //uncomment header for now
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
        }
      )
      .then((response) => {
        const indivisualSubscriptions = response.data.data.filter((itm) => itm.accessType === "National" && itm.price === itm.totalPrice).sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1);
        // console.log(indivisualSubscriptions)
        this.setState({
          items: {
            item1: Object.values(response.data.data)[0],
            item2: Object.values(response.data.data)[1],
            item3: Object.values(response.data.data)[2],
            item4: Object.values(response.data.data)[3],
            item5: Object.values(response.data.data)[6],
            item6: Object.values(response.data.data)[7],
            item7: Object.values(response.data.data)[4],
            item8: Object.values(response.data.data)[5],
            item9: Object.values(indivisualSubscriptions)[0],
            item10: Object.values(indivisualSubscriptions)[1],
            item11: Object.values(indivisualSubscriptions)[2],
            item12: Object.values(indivisualSubscriptions)[3],
          },
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          // localStorage.removeItem("token");
          // localStorage.removeItem("email");
          // localStorage.removeItem("userID");
          // localStorage.removeItem("userName");
          // localStorage.removeItem("secret_key");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("expired");
          this.props.history.push("/logout");
        }
      });
  }

  handleStateChange(event) {
    console.log(this.state.states.filter(res => res.stateID === +event.target.value).map(res => res.stateName))
    if (event.target.value !== "") {
      this.setState({
        selectedState: event.target.value,
        selectedStateName: this.state.states.filter(res => res.stateID === +event.target.value).map(res => res.stateName + ' ' + '(' + res.stateCode + ')')
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/master/getsubscription?stateId=${event.target.value}`,
          {
            //Uncomment header for now
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          const indivisualSubscriptions = response.data.data.filter((itm) => itm.accessType === "National" && itm.price === itm.totalPrice).sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1);
          this.setState({
            items: {
              item1: Object.values(response.data.data)[0],
              item2: Object.values(response.data.data)[1],
              item3: Object.values(response.data.data)[2],
              item4: Object.values(response.data.data)[3],
              item5: Object.values(response.data.data)[6],
              item6: Object.values(response.data.data)[7],
              item7: Object.values(response.data.data)[4],
              item8: Object.values(response.data.data)[5],
              item9: Object.values(indivisualSubscriptions)[0],
            item10: Object.values(indivisualSubscriptions)[1],
            item11: Object.values(indivisualSubscriptions)[2],
            item12: Object.values(indivisualSubscriptions)[3],
            },
          });
        })
        .catch((error) => {
          this.setState({
            items: {
              item1: "",
              item2: "",
              item3: "",
              item4: "",
              item5: "",
              item6: "",
              item7: "",
              item8: "",
            },
          });
        });
    } else {
      this.getSubscriptionList();
      this.setState({
        selectedState: event.target.value,
        selectedStateName: 'Alabama (AL)'
      });
    }
  }

  getCustomSubscription() {
    const userid = localStorage.getItem("userID");

    if (!userid) {
    }
    else {

      axios
        .post(
          `${process.env.REACT_APP_API_URL
          }api/Master/getCustomsubscriptionbyid?userID=${localStorage.getItem("userID")}&StateId=${this.state.selectedState}`
        )
        .then((response) => {
          this.setState({
            customSubscription: Object.values(response.data),
          });
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("secret_key");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expired");
            // this.props.history.push("/login");
          }
        });
    }
  }

  handleCheckout(id, filterState, accessType) {
    if (accessType == "National") {
      filterState = "";
    }
    const userid = localStorage.getItem("userID");

    if (!userid) {
      this.alertnotification();
    }
    else {

      axios
        .get(
          `${process.env.REACT_APP_API_URL
          }api/Subscription/getcheckoutflag?userId=${localStorage.getItem("userID")}&subscriptionId=${id}&state=${filterState}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          if (response.data == true) {
            // document.querySelector('.close').click();
            sessionStorage.setItem("subscriptionId", id);
            sessionStorage.setItem("selectedState", this.state.selectedState);
            this.props.history.push("/checkout");
          } else {

            const subData = JSON.parse(localStorage.getItem('subsId'));
            console.log(subData)
            if (subData !== undefined || subData !== null) {
              let dIndx = subData.findIndex(x => x.subid == id && x.state == 'All States' || x.subid == id && x.state == filterState);
              if (dIndx !== -1) {
                subData.splice(dIndx, 1);
                localStorage.setItem('subsId', JSON.stringify(subData));
              }
            }
            alert("You already have this subscription.");
          }
        })
        .catch((error) => {
          console.log(error)
          if (
            error.response?.status === 400 ||
            error.response?.status === 401 ||
            error.response?.status === 403
          ) {
            localStorage.clear()
            this.props.history.push("/login");
          }
        });
    }

  }

  GetMaintancesCharges(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }api/Subscription/GetMaintancesCharges?filterInput={"mySubscriptionID":"${id}"}`
      )
      .then((response) => {
        console.log(response)
        this.setState({
          MaintenanceCharges: response.data[0].maintancesCharges,
        });
      })
      .catch((error) => {
        console.log(error)
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          // this.props.history.push("/login");
        }
      });
  }

  componentDidMount() {
    this.refreshToken();
    this.getAllState();
    this.getSubscriptionList();
    this.getCustomSubscription();
  }

  render() {
    const { customSubscription } = this.state;
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main" className="subscription-bg">
          <div className="pt-3 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bg-gray mb-3 pb-5">
                        <h6 className="text-white text-center">
                          State Subscriptions
                        </h6>
                        <div className="row subscription-state">
                          <p className="text-white col-4 text-right">
                            Select State:
                          </p>
                          <select
                            className="col-8"
                            onChange={this.handleStateChange}
                          >
                            {this.state.states.map((stateArr, index) => (
                              <option value={stateArr.stateID}>
                                {stateArr.stateName} ({stateArr.stateCode})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-3 pt-3">
                    <div className="row">
                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item1.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $
                            {parseFloat(
                              this.state.items.item1.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item1.subscriptionType}
                          </p>
                          <p className="font-weight-bold">
                            $
                            {parseFloat(
                              this.state.items.item1.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.filterStates}
                            &nbsp;:{" "}
                            {
                              this.state.items.item1
                                .totalFilesCountSpecificState
                            }{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.hospitalCount}
                            &nbsp; Hospitals/Clinics

                          </p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item1.subscriptionID,
                                this.state.items.item1.filterStates,
                                this.state.items.item1.accessType
                              );
                            }}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item3.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $
                            {parseFloat(
                              this.state.items.item3.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item3.subscriptionType}
                          </p>
                          <p className="font-weight-bold">
                            $
                            {parseFloat(
                              this.state.items.item3.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item3.filterStates}
                            &nbsp;:{" "}
                            {
                              this.state.items.item3
                                .totalFilesCountSpecificState
                            }{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.hospitalCount}
                            &nbsp; Hospitals/Clinics

                          </p>
                          <button
                            className="btn bg-white checkout-btn"
                            data-toggle="modal" data-target="#Modalone"
                            onClick={(e) => {
                              const data = JSON.parse(localStorage.getItem("SubscriptionData"));
                              let hasv;
                              if (data?.length) {
                                hasv = data?.findIndex(element => element.subscriptionID === this.state.items.item3.subscriptionID && element.filterStates === this.state.items.item3.filterStates);
                                if (hasv === -1) {
                                  this.GetMaintancesCharges(this.state.items.item3.subscriptionID)
                                } else {
                                  const $ = window.$;
                                  alert('Subscription is already added to the list')
                                  e.stopPropagation();
                                  this.props.history.push("/checkout");
                                }
                              }
                              else {
                                this.GetMaintancesCharges(this.state.items.item3.subscriptionID)
                              }


                            }}
                          >
                            Checkout
                          </button>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="Modalone" tabIndex="-1" aria-labelledby="ModaloneLabel" aria-hidden="true">
                          <div className="modal-dialog modal-md">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="ModaloneLabel">How do you want to pay? </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                  {this.state.items.item3.subscriptionTitle}
                                </h2>
                                <div className="row">

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Pay Once
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item3.totalPrice
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item3.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold modalLine">
                                        $0 Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item3.subscriptionID,
                                            this.state.items.item3.filterStates,
                                            this.state.items.item3.accessType
                                          );
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Via 12 Months Installment
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item3.totalPrice / 12
                                        ).toFixed(2)}
                                        <span className="perMonth">/month</span>
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item3.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold">
                                        ${this.state.MaintenanceCharges} Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item3.subscriptionID,
                                            this.state.items.item3.filterStates,
                                            this.state.items.item3.accessType
                                          );
                                          localStorage.setItem('IsInstalmentPayment', true);
                                          localStorage.setItem('MaintenanceCharges', this.state.MaintenanceCharges);
                                          localStorage.setItem('InstallmenetAmount', parseFloat(
                                            this.state.items.item3.totalPrice / 12
                                          ).toFixed(2));
                                          let id = [];
                                          id = JSON.parse(localStorage.getItem('subsId'))
                                          let data = {
                                            'subid': this.state.items.item3.subscriptionID,
                                            'state': this.state.items.item3.filterStates
                                          }
                                          { id !== null ? id.push(data) : id = [data] }
                                          localStorage.setItem('subsId', JSON.stringify(id));
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>


                                </div>



                              </div>

                            </div>
                          </div>
                        </div>

                      </div>


                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item2.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $
                            {parseFloat(
                              this.state.items.item2.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item2.subscriptionType}
                          </p>
                          <p className="font-weight-bold">
                            $
                            {parseFloat(
                              this.state.items.item2.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item2.filterStates} &nbsp;:{" "}
                            {
                              this.state.items.item2
                                .totalFilesCountSpecificState
                            }{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.hospitalCount}
                            &nbsp; Hospitals/Clinics

                          </p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item2.subscriptionID,
                                this.state.items.item2.filterStates,
                                this.state.items.item2.accessType
                              );
                            }}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item4.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $
                            {parseFloat(
                              this.state.items.item4.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item4.subscriptionType}
                          </p>
                          <p className="font-weight-bold">
                            $
                            {parseFloat(
                              this.state.items.item4.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item4.filterStates}
                            &nbsp;:{" "}
                            {
                              this.state.items.item4
                                .totalFilesCountSpecificState
                            }{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.hospitalCount}
                            &nbsp; Hospitals/Clinics

                          </p>
                          {/* <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item4.subscriptionID,
                                this.state.items.item4.filterStates,
                                this.state.items.item4.accessType
                              );
                            }}
                          >
                            Checkout
                          </button> */}
                          <button
                            className="btn bg-white checkout-btn"
                            data-toggle="modal" data-target="#Modaltwo"
                            onClick={(e) => {
                              const data = JSON.parse(localStorage.getItem("SubscriptionData"));
                              let hasv;
                              if (data?.length) {
                                hasv = data?.findIndex(element => element.subscriptionID === this.state.items.item4.subscriptionID && element.filterStates === this.state.items.item4.filterStates);
                                console.log(hasv)
                                if (hasv === -1) {
                                  this.GetMaintancesCharges(this.state.items.item4.subscriptionID)
                                } else {
                                  const $ = window.$;
                                  alert('Subscription is already added to the list')
                                  e.stopPropagation();
                                  this.props.history.push("/checkout");
                                }
                              }
                              else {
                                this.GetMaintancesCharges(this.state.items.item4.subscriptionID)
                              }


                            }}
                          // onClick={() => {
                          //   this.GetMaintancesCharges(this.state.items.item4.subscriptionID)
                          // }}
                          >
                            Checkout
                          </button>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="Modaltwo" tabIndex="-1" aria-labelledby="ModaltwoLabel" aria-hidden="true">
                          <div className="modal-dialog modal-md">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="ModaltwoLabel">How do you want to pay? </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                  {this.state.items.item4.subscriptionTitle}
                                </h2>
                                <div className="row">

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Pay Once
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item4.totalPrice
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item4.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold modalLine">
                                        $0 Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item4.subscriptionID,
                                            this.state.items.item4.filterStates,
                                            this.state.items.item4.accessType
                                          );
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Via 12 Months Installment
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item4.totalPrice / 12
                                        ).toFixed(2)}
                                        <span className="perMonth">/month</span>
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item4.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold">
                                        ${this.state.MaintenanceCharges} Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item4.subscriptionID,
                                            this.state.items.item4.filterStates,
                                            this.state.items.item4.accessType
                                          );
                                          localStorage.setItem('IsInstalmentPayment', true);
                                          localStorage.setItem('MaintenanceCharges', this.state.MaintenanceCharges);
                                          localStorage.setItem('InstallmenetAmount', parseFloat(
                                            this.state.items.item4.totalPrice / 12
                                          ).toFixed(2));
                                          let id = [];
                                          id = JSON.parse(localStorage.getItem('subsId'))
                                          let data = {
                                            'subid': this.state.items.item4.subscriptionID,
                                            'state': this.state.items.item4.filterStates
                                          }
                                          { id !== null ? id.push(data) : id = [data] }
                                          localStorage.setItem('subsId', JSON.stringify(id));
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>


                                </div>



                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bg-gray mb-3">
                        <h6 className="text-white text-center">
                          Annual subscriptions get Rx Wholesaler data for free
                        </h6>
                        <p className="text-center font-weight-bold text-white">
                          {/* (All States) */}&nbsp;
                          <h6>
                            {" "}
                            <Link to="/ourMission" className="text-orange">
                              {" "}
                              Contact us for multi-state discounts
                            </Link>
                          </h6>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-3 pt-3">
                    <div className="row">
                      {/* <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 static-sups custom-offer">
                          <h3>Exciting<br></br>Offer<br></br>Coming Soon</h3>

                        </div>
                      
                      </div> */}

                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 position-relative" style={{ height: `calc(100% - 1rem)` }}>
                          <h2 className="subscriptionBoxInnerHeader">
                            Employers only. Contact us to get your free 3-day trial to Vindex. Our fiduciary dashboard which reveals value in your healthcare purchases
                          </h2>

                          <div className="App" style={{ position: 'absolute', bottom: '15px', left: 0, right: 0 }}>
                            <button
                              type="button" title="Mail To:  info@healthcostlabs.com"
                              className="btn bg-white checkout-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location = "mailto:info@healthcostlabs.com?subject=I want to try Vindex. Please indicate the state you wish to analyze.";
                                //window.location.href=' http://eepurl.com/hJ4Vzv';

                              }}
                            >
                              <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                              Try Vindex</button>


                          </div>

                        </div>
                      </div>

                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 ">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item7.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $ 0
                            {/* {parseFloat(
                              this.state.items.item7.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })} */}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item7.subscriptionType} (Includes
                            Data Feed)
                          </p>
                          <p className="font-weight-bold">
                            $
                            {parseFloat(
                              this.state.items.item7.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            All States :{" "}
                            {this.state.items.item7.totalFilesCountAllStates}{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.totalHospitalCountAllStates}
                            &nbsp; Hospitals/Clinics

                          </p>

                          {/* <button
                            className="btn bg-white checkout-btn"
                            data-toggle="modal" data-target="#Modalthree"
                            onClick={(e) => {
                              const data = JSON.parse(localStorage.getItem("SubscriptionData"));
                              let hasv;
                              if (data?.length) {
                                hasv = data?.findIndex(element => element.subscriptionID === this.state.items.item7.subscriptionID && element.filterStates === 'All States');
                                console.log(hasv)
                                if (hasv === -1) {
                                  this.GetMaintancesCharges(this.state.items.item7.subscriptionID)
                                } else {
                                  const $ = window.$;
                                  alert('Subscription is already added to the list')
                                  e.stopPropagation();
                                  this.props.history.push("/checkout");
                                }
                              }
                              else {
                                this.GetMaintancesCharges(this.state.items.item7.subscriptionID)
                              }


                            }}
                          // onClick={() => {
                          //   this.GetMaintancesCharges(this.state.items.item7.subscriptionID)
                          // }}
                          >
                            Checkout
                          </button> */}
                          <button
                            type="button" title="Mail To:  info@healthcostlabs.com"
                            className="btn bg-white checkout-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location = "mailto:info@healthcostlabs.com?subject=National Data Access";
                              //window.location.href=' http://eepurl.com/hJ4Vzv';

                            }}
                          >
                            <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                            Negotiable</button>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="Modalthree" tabIndex="-1" aria-labelledby="ModalthreeLabel" aria-hidden="true">
                          <div className="modal-dialog modal-md">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="ModalthreeLabel">How do you want to pay? </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                  {this.state.items.item7.subscriptionTitle}
                                </h2>
                                <div className="row">

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Pay Once
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item7.totalPrice
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item7.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold modalLine">
                                        $0 Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item7.subscriptionID,
                                            this.state.items.item7.filterStates,
                                            this.state.items.item7.accessType
                                          );
                                          localStorage.removeItem('subsIdAllState')
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="bg-orange tiles mb-3 height-auto">
                                      <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                        Via 12 Months Installment
                                      </h2>
                                      <h3 className="font-weight-bold text-white">
                                        $
                                        {parseFloat(
                                          this.state.items.item7.totalPrice / 12
                                        ).toFixed(2)}
                                        <span className="perMonth">/month</span>
                                      </h3>
                                      <p className="text-body">
                                        {this.state.items.item7.subscriptionType}
                                      </p>
                                      <p className="font-weight-bold">
                                        ${this.state.MaintenanceCharges} Maintenance Fee
                                      </p>

                                      <button
                                        className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                          this.handleCheckout(
                                            this.state.items.item7.subscriptionID,
                                            this.state.items.item7.filterStates,
                                            this.state.items.item7.accessType
                                          );
                                          localStorage.setItem('IsInstalmentPayment', true);
                                          localStorage.setItem('MaintenanceCharges', this.state.MaintenanceCharges);
                                          localStorage.setItem('InstallmenetAmount', parseFloat(
                                            this.state.items.item7.totalPrice / 12
                                          ).toFixed(2));
                                          let id = [];
                                          id = JSON.parse(localStorage.getItem('subsId'));
                                          let data = {
                                            'subid': this.state.items.item7.subscriptionID,
                                            'state': this.state.items.item7.filterStates
                                          }
                                          { id !== null ? id.push(data) : id = [data] }
                                          localStorage.setItem('subsId', JSON.stringify(id));
                                          const alState = {
                                            'subid': this.state.items.item7.subscriptionID,
                                            'state': this.state.items.item7.filterStates,
                                            'allState': true
                                          }
                                          localStorage.setItem('subsIdAllState', JSON.stringify(alState));
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </div>


                                </div>



                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 static-sups">
                          <h2 className="subscriptionBoxInnerHeader">
                            Pick specific hospitals for Cost Comparison Tool
                          </h2>
                          <h3 className="font-weight-bold text-white d-none d-md-block">
                            &nbsp;
                          </h3>
                          <p className="text-body d-none d-md-block">
                            &nbsp;
                          </p>
                          <h3 className="font-weight-bold text-white">
                            $75/File
                          </h3>
                          
                          <button
                            type="button" title="Mail To:  info@healthcostlabs.com"
                            className="btn bg-white checkout-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location = "mailto:info@healthcostlabs.com?subject=Pick specific hospitals for Cost Comparison Tool";
                              //window.location.href=' http://eepurl.com/hJ4Vzv';

                            }}
                          >
                            <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                            Contact Us</button>
                        </div>
                      </div> */}
                      <div className="col-md-12 subscription-col">
                        <div className="bg-orange tiles mb-3">
                          <h2 className="subscriptionBoxInnerHeader">
                            {this.state.items.item8.subscriptionTitle}
                          </h2>
                          <h3 className="font-weight-bold text-white">
                            $
                            0
                            {/* {parseFloat(
                              this.state.items.item8.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}*/}
                          </h3>
                          <p className="text-body">
                            {this.state.items.item8.subscriptionType} (Includes
                            Data Feed)
                          </p>
                          <p className="font-weight-bold">
                            $
                            0
                            {/*{parseFloat(
                              this.state.items.item8.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}*/}
                            /File
                          </p>
                          <p className="font-weight-bold">
                            All States :{" "}
                            {this.state.items.item8.totalFilesCountAllStates}{" "}
                            Files
                          </p>
                          <p className="font-weight-bold">
                            {this.state.items.item1.totalHospitalCountAllStates}
                            &nbsp; Hospitals/Clinics

                          </p>
                          {/*  <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item8.subscriptionID,
                                this.state.items.item8.filterStates,
                                this.state.items.item8.accessType
                              );
                            }}
                          >
                            Checkout
                          </button>*/}
                          <div className="App">
                            <button
                              type="button" title="Mail To:  info@healthcostlabs.com"
                              className="btn bg-white checkout-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location = "mailto:info@healthcostlabs.com?subject=National Data Access";
                                //window.location.href=' http://eepurl.com/hJ4Vzv';

                              }}
                            >
                              <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                              Negotiable</button>


                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-sm-12"><h2 className="font-weight-bold text-white text-center">
                  Individual Search Subscription
                </h2></div>

                <div className="col-md-6">
                  <div className="bg-white px-3 pt-3">
                    <div className="row">

                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 text-center">
                          <h3 className="subscriptionBoxInnerHeader">{this.state.items.item9.subscriptionTitle}</h3>
                          <h3 className="font-weight-bold text-white"> $
                            {parseFloat(
                              this.state.items.item9.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}</h3>
                          <p className="text-body">{this.state.items.item9.subscriptionType}</p>
                          <p className="font-weight-bold">
                            {this.state.items.item9.priceDescription}
                          </p>
                          <p className="text-body">All States</p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item9.subscriptionID,
                                this.state.items.item9.filterStates,
                                this.state.items.item9.accessType
                              );
                              localStorage.removeItem('subsIdAllState')
                              localStorage.setItem('IsIndvSubscription', true);
                            }}
                          >
                            Checkout
                          </button>
                          {/* <button
                            
                          </button> */}
                        </div>
                        
                      </div>

                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 text-center">
                          <h3 className="subscriptionBoxInnerHeader">{this.state.items.item10.subscriptionTitle}</h3>
                          <h3 className="font-weight-bold text-white"> $
                            {parseFloat(
                              this.state.items.item10.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}</h3>
                          <p className="text-body">{this.state.items.item10.subscriptionType}</p>
                          <p className="font-weight-bold">
                            {this.state.items.item10.priceDescription}
                          </p>
                          <p className="text-body">All States</p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item10.subscriptionID,
                                this.state.items.item10.filterStates,
                                this.state.items.item10.accessType
                              );
                              localStorage.removeItem('subsIdAllState')
                              localStorage.setItem('IsIndvSubscription', true);
                            }}
                          >
                            Checkout
                          </button>
                          {/* <button
                            
                          </button> */}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="bg-white px-3 pt-3">
                    <div className="row">
                    <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 text-center">
                          <h3 className="subscriptionBoxInnerHeader">{this.state.items.item11.subscriptionTitle}</h3>
                          <h3 className="font-weight-bold text-white"> $
                            {parseFloat(
                              this.state.items.item11.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}</h3>
                          <p className="text-body">{this.state.items.item11.subscriptionType}</p>
                          <p className="font-weight-bold">
                            {this.state.items.item11.priceDescription}
                          </p>
                          <p className="text-body">All States</p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item11.subscriptionID,
                                this.state.items.item11.filterStates,
                                this.state.items.item11.accessType
                              );
                              localStorage.removeItem('subsIdAllState')
                              localStorage.setItem('IsIndvSubscription', true);
                            }}
                          >
                            Checkout
                          </button>
                          {/* <button
                            
                          </button> */}
                        </div>
                        
                      </div>

                      <div className="col-md-6 subscription-col">
                        <div className="bg-orange tiles mb-3 text-center">
                          <h3 className="subscriptionBoxInnerHeader">{this.state.items.item12.subscriptionTitle}</h3>
                          <h3 className="font-weight-bold text-white"> $
                            {parseFloat(
                              this.state.items.item12.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}</h3>
                          <p className="text-body">{this.state.items.item12.subscriptionType}</p>
                          <p className="font-weight-bold">
                            {this.state.items.item12.priceDescription}
                          </p>
                          <p className="text-body">All States</p>
                          <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item12.subscriptionID,
                                this.state.items.item12.filterStates,
                                this.state.items.item12.accessType
                              );
                              localStorage.removeItem('subsIdAllState')
                              localStorage.setItem('IsIndvSubscription', true);
                            }}
                          >
                            Checkout
                          </button>
                          {/* <button
                            
                          </button> */}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {customSubscription.length > 0 && (
                <div className="row mt-3 pl-3 pr-3">
                  <h2 className="font-weight-bold text-white text-center">
                    Customized Offer For You
                  </h2>

                  <table className="table  bg-orange">
                    <tbody>
                      {customSubscription.map((subscription, index) => (
                        <tr>
                          <td className="subscriptionBoxInnerHeader pl-3">
                            {subscription.subscriptionTitle}
                            <br />$
                            {parseFloat(subscription.price).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                            /File &nbsp; Total files &nbsp;
                            {subscription.accessType == "National" ? (
                              <span>
                                {subscription.totalFilesCountAllStates} Files
                              </span>
                            ) : (
                              <span>
                                {subscription.totalFilesCountSpecificState}{" "}
                                Files
                              </span>
                            )}
                          </td>

                          <td className="subscriptionBoxInnerHeader pl-3">
                            &nbsp;{" "}
                            <h3 className="text-white font-weight-bold">
                              $
                              {parseFloat(
                                subscription.totalPrice
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </h3>
                          </td>
                          <td>
                            <button
                              className="btn bg-white checkout-btn"
                              onClick={() => {
                                this.handleCheckout(
                                  subscription.subscriptionID,
                                  subscription.filterStates,
                                  subscription.accessType
                                );
                              }}
                            >
                              Checkout
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className=" mt-3">
                <h4 className="alert-info text-center pt-3 pb-3">
                  &#9960; Access to Vindex is included with annual subscriptions with Medicare pricing
                </h4>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default withRouter(Subscription);
