import axios from "axios";
import React, { useState } from "react";

const AutoRenew = ({ subscid, isactive, state, subscriptiontype }) => {
    const [autoRenewStatus, setAutoRenewStatus] = useState(isactive);
    const [InProcess, setInProcess] = useState(false);

    const toggleAutoRenew = () => {
        const newStatus = !autoRenewStatus;
        setAutoRenewStatus(newStatus);
        setInProcess(true)
        const data = {
            userID: localStorage.getItem("userID"),
            mySubscriptionID: subscid,
            autoRenewStatus: newStatus
        }
        console.log(data)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/subscription/updateAutoRenewal`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                let message = `Your subscription auto-renewal for ${state} (${subscriptiontype}) has been ${newStatus ? 'activated' : 'deactivated'}.`
                
                if (response.data.status) { alert(message) }
                else {
                    alert(response.data.message)
                }
                setInProcess(false)

            })
            .catch((error) => {
                alert("Try Again!!")
                setInProcess(false)
                // if (
                //     error.response.status === 400 ||
                //     error.response.status === 401 ||
                //     error.response.status === 403
                // ) {
                //     localStorage.removeItem("token");
                //     localStorage.removeItem("email");
                //     localStorage.removeItem("userID");
                //     localStorage.removeItem("userName");
                //     localStorage.removeItem("secret_key");
                //     localStorage.removeItem("refreshToken");
                //     localStorage.removeItem("expired");
                //     this.props.history.push("/logout");
                // }
            });
    };

    return (
        <>
        {
            InProcess ? <div className="loader renew-loader"></div> : <button
            type="button"
            className={`btn btn-sm btn-toggle ${autoRenewStatus ? 'active' : ''}`}
            onClick={toggleAutoRenew}
            aria-pressed={autoRenewStatus}
        >
            <div className="handle"></div>
        </button>
        }
        
        
        </>
    );
};

export default AutoRenew;
