import axios from "axios";
import _ from 'lodash';
import React, { Component } from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import lowCostPopupContext from '../data/popup-text.json';



class Home extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.selectRef = React.createRef();
        this.externalLinks = React.createRef();
        this.unclickableArea = React.createRef()
        this.codeDescriptionRef = React.createRef()
        this.state = {
            token: localStorage.getItem("token"),
            medicalCode: '',
            stateName: '',
            stateCode: '',
            msaName: '',
            msaID: '',
            errorMessage: "",
            Setting: [],
            states: [],
            msa: [],
            lives: '',
            accumulator: '',
            hasApi: '',
            lowCostPopupContext: lowCostPopupContext,
            hasSentMessage: false,
            mobileNumber: '',
            countryNumber: '+1',
            hasOpenPhone: false,
            isOOPSearchable: false,
            nextSearchDate: '',
            unclickableAreaheight: '',
            captchaResponse: null,
            codeDiscription: [],
            codeDiscriptionFilterd: [],
            codeDiscriptionPopup: false,
            searchText: '',
            isCodeDescriptionLoade: false,
            msaTableName: '',
            paymentMethod: '',
            individualSubscription: false,
            individualSubscriptionCount:0,
            reportType:null,
            downloadInProcess:false,
        };
        this.oopSubmit = this.oopSubmit.bind(this);
        this.oopCustomSubmit = this.oopCustomSubmit.bind(this);
        this.alertChange = this.alertChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.numberValid = this.numberValid.bind(this);
        this.getReportType = this.getReportType.bind(this);
        this.handleMsaChange = this.handleMsaChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.loadOptions = _.debounce(this.loadOptions.bind(this), 100);
        this.handelCodeSelect = this.handelCodeSelect.bind(this);
        this.getAllSetting = this.getAllSetting.bind(this);
        this.handleSettingChange = this.handleSettingChange.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
    }

    handleClickOutside = event => {
        const domNode = reactDom.findDOMNode(this.codeDescriptionRef.current);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                codeDiscriptionPopup: false
            });
        }
    }

    refreshToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const expired = localStorage.getItem("expired");

        if (token !== null && refreshToken !== null && expired !== null) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
                    {
                        token: token,
                        refreshToken: refreshToken,
                        expiration: expired,
                    }
                )
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expiration);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        localStorage.removeItem("secret_key");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expired");
                        this.props.history.push("/login");
                    }
                });
        }
    }

    getaccessAllState() {
        const reportStatus = JSON.parse(localStorage.getItem("reportType"));
        const umail = reportStatus.replace_Email !== null ?
            reportStatus.replace_Email : reportStatus.actual_Email;

        axios
            .get(
                `${process.env.REACT_APP_API_URL
                }api/OobRates/getoobratesStatewiseUserAccess?email=${umail}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    states: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.clear()
                    this.props.history.push("/logout");
                }
            });
    }

    getAllSetting() {
        axios
            .get(
                `${process.env.REACT_APP_API_URL
                }api/OobRates/getoobratesSetting`,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    Setting: response.data,
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.clear()
                    this.props.history.push("/logout");
                }
            });
    }

    handleStateChange(event) {
        this.setState({
            stateName: event.target.value,
            msaName: '',
            codeDiscription: [],
            codeDiscriptionFilterd: [],
            searchText: '',
            medicalCode: ''
        });
        this.selectRef.current.setValue('')
        const stateFilter = this.state.states.filter(i => i.stateName === event.target.value);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/Subscription/getmsafilter?stateCode=${stateFilter[0].stateCode}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    msa: Object.values(response.data),
                    stateCode: stateFilter[0].stateCode
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });
    }

    handleSettingChange(event) {
        this.setState({
            paymentMethod: event.target.value
        });
    }

    checkEmployersAccess() {
        const reportStatus = JSON.parse(localStorage.getItem("reportType"));
        const umail = reportStatus.replace_Email !== null ?
            reportStatus.replace_Email : reportStatus.actual_Email;
        const filter = {
            'accout': umail,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_URL
                }api/OobRates/getoobratesEmployersAccess`, filter,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    hasApi: response.data[0].API
                })
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.clear()
                    this.props.history.push("/logout");
                }
            });
    }

    // oop filter 
    oopSubmit() {
        if (this.state.token !== null) {
            const stateFilter = this.state.states.filter(i => i.stateName === this.state.stateName);
            const filter = {
                'stateName': this.state.stateName,
                'msaID': this.state.msaID,
                'msaName': this.state.msaName,
                'code': this.state.medicalCode,
                'accout': localStorage.getItem('userName'),
                'stateCode': stateFilter[0].stateCode,
                'paymentMethod': this.state.paymentMethod,
                'tableName': this.state.msaTableName
            }
            sessionStorage.removeItem("oopflcus")
            sessionStorage.setItem("oopfl", JSON.stringify(filter));
            this.props.history.push("/ooprates");

        } else {
            this.setState({
                errorMessage: "You need to login first"
            });
        }
    }

    async handleExportClick() {
        const reportStatus = JSON.parse(localStorage.getItem("reportType"));
        const umail = reportStatus.replace_Email !== null ?
            reportStatus.replace_Email : reportStatus.actual_Email;
    
        const filter = {
            'code': this.state.medicalCode.procedureCode,
            'accout': umail,
            'stateName': this.state.stateName,
            'msaID': this.state.msaID,
            'msaName': this.state.msaName,
            'paymentMethod': this.state.paymentMethod.toUpperCase(),
            'userId': localStorage.getItem("userID")
        };
    
        try {
            this.setState({downloadInProcess:true})
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}api/OobRates/exportOobData?oobinput=${JSON.stringify(filter)}`, 
                {
                    responseType: 'blob', // Blob response for file download
                    // onDownloadProgress: (progressEvent) => {
                    //     if (progressEvent.total) {
                    //       const percentCompleted = Math.round(
                    //         (progressEvent.loaded * 100) / progressEvent.total
                    //       );
                    //       this.setState({ progress: percentCompleted }); // Update progress in state
                    //       console.log(percentCompleted); // Log progress for debugging
                    //     }
                    //   },
                }
            );
        
            // Extract filename from Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            let filename = `${this.state.stateName}.xlsx`; // Default filename
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = decodeURIComponent(filenameMatch[1]);
                }
            }
        
            // Create a URL for the file and initiate download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Use the extracted filename
            document.body.appendChild(link);
            link.click();
        
            // Clean up
            window.URL.revokeObjectURL(url);
            link.remove();
        
            // Call the second API after the file download is initiated successfully
            const followUpResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/OobRates/logIndvsubscount`,{
                headers: {
                    "userId":localStorage.getItem("userID")
                }
            });
            this.setState({downloadInProcess:false})
            this.getReportType()
        } catch (error) {
            this.setState({downloadInProcess:false})
            console.error('An error occurred:', error);
        }
        
    }
    
    
    // oop filter 
    oopCustomSubmit() {
        if (this.state.token !== null) {
            const stateFilter = this.state.states.filter(i => i.stateName === this.state.stateName);
            const filter = {
                'stateName': this.state.stateName,
                'msaID': this.state.msaID,
                'msaName': this.state.msaName,
                'code': this.state.medicalCode,
                'lives': this.state.lives,
                'accumulator': this.state.accumulator,
                'accout': localStorage.getItem('userName'),
                'stateCode': stateFilter[0].stateCode,
                'paymentMethod': this.state.paymentMethod,
                'tableName': this.state.msaTableName
            }
            // console.log(this.state.stateName, this.state.msaName, this.state.medicalCode, this.state.lives, this.state.accumulator)
            sessionStorage.removeItem("oopfl")
            sessionStorage.setItem("oopflcus", JSON.stringify(filter));
            this.props.history.push("/ooprates");

        } else {
            this.setState({
                errorMessage: "You need to login first"
            });
        }
    }

    alertChange() {
        this.setState({
            errorMessage: ""
        });
    }

    numberValid(num) {
        const re = /^[0-9\b]+$/;
        return re.test(num)
    }

    getReportType() {
        const accout = localStorage.getItem('userName');
        const userId = localStorage.getItem('userID');

        if (this.state.token !== null && accout !== null) {

            axios
                .get(
                    `${process.env.REACT_APP_API_URL
                    }api/OobRates/getReportType?email=${accout}&userId=${userId}`
                )
                .then((response) => {
                    localStorage.setItem("reportType", JSON.stringify(response.data[0]));
                    console.log(response.data[0].searching_Status)
                    if (response.data[0].searching_Status === 1) {
                        this.getaccessAllState();
                        this.checkEmployersAccess();
                        this.setState({
                            isOOPSearchable: true
                        });
                        console.warn(response.data[0].searching_Status)
                    } else {
                        this.setState({
                            isOOPSearchable: false,
                            nextSearchDate: new Date(response.data[0]?.next_Search_Date)
                        });
                    }
                    this.setState({
                        individualSubscription: response.data[0].individualSubscription,
                        individualSubscriptionCount: response.data[0].individualSubscriptionCount,
                        reportType:response.data[0].reportType
                    });
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        localStorage.clear()
                        this.props.history.push("/logout");
                    }
                });

        } else {
            this.setState({
                errorMessage: "You need to login first"
            });
        }
    }

    withoutLoginClick() {
    }

    handleMsaChange(event) {
        const TableNameFilter = this.state.msa.filter(i => i.msaID === +event.target.value);
        this.setState({
            msaID: event.target.value,
            msaName: event.target.selectedOptions[0].getAttribute('msaname'),
            codeDiscription: [],
            codeDiscriptionFilterd: [],
            searchText: '',
            isCodeDescriptionLoade: true,
            msaTableName: TableNameFilter[0]?.TableName,
            medicalCode: ''
        })
        this.selectRef.current.setValue('');
    }

    handleSearchChange(value) {
        this.setState({
            searchText: value,
        });
        return value;
    }

    getOptionValue = (option) => option.procedureCode;

    getOptionLabel = (option) => `(${option.procedureCode}) ${option.codeDescription}`;

    // load options using API call
    loadOptions(value, callback) {
        const fdata = {
            stateCode: this.state.stateCode,
            msaName: this.state.msaName,
            queryText: this.state.searchText,
        }
        if (!value) {
            return callback([]);
        }
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/OobRates/GetCodeDescriptionbyMSA?FilterJson=${JSON.stringify(fdata)}`
            )
            .then((response) => {

                if (response.data.length) {
                    let dataModified;
                    dataModified = response?.data?.map(
                        obj => {
                            return {
                                "procedureCode": obj?.procedureCode.trim(),
                                "codeDescription": obj?.codeDescription.trim()
                            }
                        }
                    );
                    this.setState({
                        codeDiscription: dataModified
                    });
                    callback(this.mapOptionsToValues(dataModified))

                    sessionStorage.setItem("codeDiscription", JSON.stringify(dataModified));
                } else {
                    this.setState({
                        codeDiscription: []
                    });
                    callback(this.mapOptionsToValues([]))
                    sessionStorage.setItem("codeDiscription", JSON.stringify([]));
                }

            })
            .catch((error) => {
                console.log(error)
            });


    };

    handelCodeSelect(event) {
        console.log(event)
        this.setState({
            medicalCode: event
        })
    }

    mapOptionsToValues = options => {
        return options.map(option => ({
            procedureCode: option.procedureCode,
            codeDescription: option.codeDescription
        }));
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        this.refreshToken();
        if (this.state.token !== null) {
            this.unclickableArea.current.style.display = "none";
            this.getReportType();
            this.getAllSetting();
        } else {
            this.unclickableArea.current.style.display = "block";
            //console.log(this.externalLinks.current.clientHeight)
            this.setState({
                unclickableAreaheight: this.externalLinks.current.clientHeight
            })
        }

    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }




    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="home-banner-image py-3">
                        <div className="container py-4">
                            <div className="row">
                                <div className="col-md-7 pt-3">
                                    <h4 className="text-white bannerFontsize">
                                        <em>
                                            Out of pocket cost is derived from the negotiated rate.  A lower negotiated rate yields a lower out of pocket cost.  Choose plans with a lower negotiated rate and you save money.
                                        </em>
                                    </h4>
                                    {/* <p className="text-white">
                                        <em>--- Thomas Sowell</em>
                                    </p> */}

                                    <div className="row justify-content-center mt-1  ml-0 mr-0 oop-m position-relative" >

                                        {
                                            this.state.errorMessage ? <><div className="col-md-12"><div className="alert alert-danger alert-dismissible fade show py-2" role="alert">
                                                {this.state.errorMessage}
                                                <button type="button" className="close py-2" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div></div> </> :
                                                ''
                                        }

                                        {/* if all are unclickable  */}
                                        <div className="area_unclickable" ref={this.unclickableArea} style={{ height: `calc(100% - ${this.state.unclickableAreaheight}px)` }} data-toggle="modal" data-target="#unclickable_Modal"></div>

                                        {/* payment Under process modal  */}
                                        <div className="modal fade" id="unclickable_Modal" data-backdrop="static">
                                            <div className="modal-dialog modal-dialog-scrollable payment_process_modal modal-dialog-centered">
                                                <div className="modal-content ">

                                                    <div className="modal-body unclickable_Modal_body">
                                                        <button type="button" className="close" data-dismiss="modal">
                                                            &times;
                                                        </button>
                                                        <p>Please create an account or login first</p>
                                                        <Link
                                                            to="/registration"
                                                            data-dismiss="modal"
                                                            onClick={() => this.props.history.push("/registration")}
                                                        >Create New Account
                                                        </Link>|
                                                        <Link
                                                            to="/login"
                                                            data-dismiss="modal"
                                                            onClick={() => this.props.history.push("/login")}
                                                        >Already have a account
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12  my-2 pl-2 pr-2">
                                            <select
                                                className="md-form mdb-select white-bg form-control w-100"
                                                name="selectState"
                                                onChange={(e) => this.handleStateChange(e)}
                                                required
                                                value={this.state.stateName}
                                                disabled={this.state.token === null || this.state.states[0]?.stateName === undefined}

                                            >
                                                <option value="">Select State</option>
                                                {
                                                    this.state.states[0]?.stateName !== undefined ?
                                                        this.state.states
                                                            .sort((a, b) => a.stateName.localeCompare(b.stateName))
                                                            .map((stateArr, index) => (
                                                                <option
                                                                    value={
                                                                        stateArr.stateName
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {`${stateArr.stateName} (${stateArr.stateCode})`}
                                                                </option>
                                                            ))
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-sm-6  col-12 my-2  pl-2 pr-2">
                                            <select
                                                className="md-form mdb-select white-bg form-control w-100"
                                                name="selectMsa"
                                                // onChange={(e) => this.setState({ msaID: e.target.value, msaName: e.target.selectedOptions[0].getAttribute('msaname') })}
                                                onChange={(e) => this.handleMsaChange(e)}
                                                required
                                                value={this.state.msaID}
                                                disabled={this.state.token === null || this.state.stateName === ''}
                                            >
                                                <option value="">Select Metro Area</option>
                                                {
                                                    this.state.msa !== undefined ?
                                                        this.state.msa.map((msaArr, index) => (
                                                            <option value={msaArr.msaID} msaname={msaArr.MSA} key={index}>{msaArr.MSA}</option>
                                                        ))
                                                        : ''
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12 my-2 pl-2 pr-2">
                                            <AsyncSelect
                                                ref={this.selectRef}
                                                cacheOptions
                                                placeholder={'Medical Code / Code Description'}
                                                noOptionsMessage={() => this.state.searchText === '' ? 'Please enter some value to search Medical Code / Code Description' : 'Nothing found'}
                                                loadingMessage={() => 'Searching...'}
                                                defaultOptions={this.state.codeDiscription}
                                                defaultValue={this.state.medicalCode}
                                                loadOptions={this.loadOptions}
                                                getOptionValue={this.getOptionValue}
                                                getOptionLabel={this.getOptionLabel}
                                                onInputChange={(e) => this.handleSearchChange(e)}
                                                onChange={this.handelCodeSelect}
                                                isClearable
                                                isDisabled={this.state.stateName == '' || this.state.msaName == '' || this.state.msaID == '' ? true : false}
                                            />

                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12  my-2 pl-2 pr-2">
                                            <select
                                                className="md-form mdb-select white-bg form-control w-100"
                                                name="paymentMethod"
                                                onChange={(e) => this.handleSettingChange(e)}
                                                required
                                                value={this.state.paymentMethod}
                                                disabled={this.state.token === null || !this.state.Setting.length}

                                            >
                                                <option value="">Select Billing Class</option>
                                                {
                                                    this.state.Setting.length > 0 ?
                                                        this.state.Setting
                                                            // .sort((a, b) => a.localeCompare(b))
                                                            .map((stateArr, index) => (
                                                                <option
                                                                    value={
                                                                        stateArr
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {stateArr}
                                                                </option>
                                                            ))
                                                        : ''
                                                }
                                            </select>
                                        </div>

                                        <div className="col-12 my-2 pl-2 pr-2">
                                            {
                                                this.state.individualSubscription && this.state.isOOPSearchable && this.state.individualSubscriptionCount > 0 ?
                                                    <button className="btn btn-orange bannerseachPlaceHoldercolor home_search_btn" disabled={this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaName === '' || this.state.codeDiscriptionPopup || this.state.paymentMethod == '' || this.state.downloadInProcess } onClick={this.handleExportClick}>
                                                        {
                                                            this.state.downloadInProcess ? 
                                                            'Downloading...' : 'Get Rates'
                                                        }
                                                        </button>
                                                    :
                                                    this.state.hasApi?.toLowerCase().trim('') === 'yes' ?
                                                        <button className="btn btn-orange bannerseachPlaceHoldercolor home_search_btn" disabled={this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaName === '' || this.state.codeDiscriptionPopup || this.state.paymentMethod == ''} onClick={this.oopSubmit}>Get Rates</button>
                                                        :
                                                        <>
                                                            {
                                                                this.state.hasApi?.toLowerCase().trim('') === 'no' ?
                                                                    <button className="btn btn-orange bannerseachPlaceHoldercolor home_search_btn" disabled={this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaName === '' || this.state.codeDiscriptionPopup || this.state.paymentMethod == ''} data-toggle="modal" data-target="#OOP_Modal">Get Rates</button>
                                                                    :
                                                                    <button className="btn btn-orange bannerseachPlaceHoldercolor home_search_btn" disabled='true' >Get Rates</button>
                                                            }

                                                        </>

                                            }

                                            {/* <span data-toggle="modal"
                                                data-target="#OOP_Modal" ref={this.ref}></span> */}
                                        </div>
                                        {/* banner link */}
                                        <div className="col-12 pl-2 pr-2" ref={this.externalLinks}>
                                            {
                                                !this.state.isOOPSearchable && this.state.nextSearchDate !== '' ?
                                                    <p className="next_search_text"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {`Your next eligible search begins on ${this.state.nextSearchDate.toLocaleDateString('en-US')}`}</p> : ''
                                            }

{
                                                !this.state.isOOPSearchable && this.state.individualSubscriptionCount > 0 &&  this.state.reportType != null ?
                                                    <p className="next_search_text"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {`Your subscription has expired. Renew within 24 hours to carry forward ${this.state.individualSubscriptionCount} remaining searches along with new search credits.`}</p> : ''
                                            }

                                            <p style={{ color: "#e8ac4c", marginBottom: '0.6rem' }}>
                                            <i class="fa fa-info-circle" aria-hidden="true"></i> This information is provided free of charge to help you with your medical bills.  If your intent is to use this data commercially please buy a subscription from us.  Thank you.
                                            </p>
                                            {/* <a href="https://ratings.leapfroggroup.org/" target="_blank" title="Search Leapfrog’s Hospital and Surgery Center Ratings | Hospital and Surgery Center Ratings | Leapfrog Group" className="ext_link"><i className="fa fa-external-link" aria-hidden="true"></i>&nbsp;Hospital and Surgery Center Ratings</a>
                                            <a href="https://www.medicare.gov/care-compare/?providerType=Physician&redirect=true#search" target="_blank" title="Find Healthcare Providers: Compare Care Near You | Medicare" className="ext_link"><i className="fa fa-external-link" aria-hidden="true"></i>&nbsp;Find Healthcare Providers: Compare Care Near You | Medicare</a>
                                            <a href="javascript:;" data-toggle="modal" data-target="#Omega_Notes" title="Omega Notes" className="ext_link"><i className="fa fa-window-restore" aria-hidden="true"></i>&nbsp;Omega Notes</a> */}
                                            {/* <a href="https://www.jasonhealth.com/" target="_blank" title="Walk-in Blood Tests in a Lab Near You - Order Online - Jason Health" className="ext_link"><i className="fa fa-external-link" aria-hidden="true"></i>&nbsp;Walk-in Blood Tests in a Lab Near You - Order Online - Jason Health</a> */}
                                            <a href="javascript:;" data-toggle="modal" data-target="#buy_low_cost_Modal" title="How to buy low cost healthcare" className="ext_link"><i className="fa fa-window-restore" aria-hidden="true"></i>&nbsp;How to buy low cost healthcare</a>
                                            <a href="javascript:;" data-toggle="modal" data-target="#Surgical_Imaging" title="Are you a Surgical or Imaging Center" className="ext_link"><i className="fa fa-window-restore" aria-hidden="true"></i>&nbsp;Are you a Surgical or Imaging Center</a>
                                            <a href="https://www.gnanow.org/" target="_blank" title=" Independent Patient Advocates | Greater National Advocates (gnanow.org)" className="ext_link"><i className="fa fa-external-link" aria-hidden="true"></i>&nbsp;Overwhelmed with healthcare and need an advocate?</a>
                                            {/* <a href="javascript:;" data-toggle="modal" data-target="#Hospital_Survey" title="Hospital Survey" className="ext_link"><i className="fa fa-window-restore" aria-hidden="true"></i>&nbsp;Hospital Survey</a> */}

                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* oop modal  */}
                        <div className="modal fade" id="OOP_Modal" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-scrollable oop_modal">
                                <div className="modal-content ">
                                    <div className="modal-body ">
                                        <button type="button" className="close" data-dismiss="modal">
                                            &times;
                                        </button>
                                        <div className="form-group">
                                            <label className="oopLabel d-block">Select family status: </label>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="oopFamilyStatus1" name="oopFamilyStatus" className="custom-control-input"
                                                    onChange={() => this.setState({ lives: '1' })} />
                                                {/* checked={this.state.lives === '1'} */}
                                                <label className="custom-control-label" htmlFor="oopFamilyStatus1">Single</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="oopFamilyStatus2" name="oopFamilyStatus" className="custom-control-input"
                                                    onChange={() => this.setState({ lives: '2' })} />
                                                {/* checked={this.state.lives === '2'} */}
                                                <label className="custom-control-label" htmlFor="oopFamilyStatus2">Family</label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="oopLabel">Enter YTD medical spend: </label>
                                            <input type="number" className="form-control mt-1" placeholder="YTD medical spend: $$$$$" value={this.state.accumulator || ''} onChange={(e) => this.setState({ accumulator: e.target.value })} disabled={this.state.token === null} pattern="[0-9]*" inputMode="numeric" />
                                        </div>

                                        <button className="btn btn-orange bannerseachPlaceHoldercolor m-auto d-block" data-dismiss="modal" disabled={this.state.stateName == '' || this.state.accumulator == '' || this.state.msaName === '' || this.state.lives === '' || this.state.accumulator === '' || !this.numberValid(this.state.accumulator) || this.state.paymentMethod == ''} onClick={this.oopCustomSubmit}>Get Rates</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* How to buy low cost healthcare modal  */}
                        <div className="modal fade" id="buy_low_cost_Modal">
                            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                <div className="modal-content ">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.lowCostPopupContext?.headding}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body ">
                                        <ol>

                                            {
                                                this.state.lowCostPopupContext?.content.map(
                                                    (text, key) => <li dangerouslySetInnerHTML={{ __html: text.text }} key={key}></li>)
                                            }

                                        </ol>



                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Are you a Surgical or Imaging Center  */}
                        <div className="modal fade" id="Surgical_Imaging">
                            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                <div className="modal-content ">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Are you a Surgical or Imaging Center</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body ">
                                        <p >If you would like your rates added to our mobile app please contact us at <a href="mailto:info@healthcostlabs.com">info@healthcostlabs.com</a>.  Please include two rows for every cpt code and plan name/cash rates.  One row is the facility only cost and the second row contains the facility cost + anesthesia or radiologist read fee. Please label each row accordingly.  We will also need all of your locations too.  There is no cost to you, no contracts, and you may change your cash rates as frequently as you like as you calibrate your cash rates to market prices.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Omega Notes  */}
                        <div className="modal fade" id="Omega_Notes">
                            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                <div className="modal-content ">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Omega Notes</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body ">
                                        <p >Omega Notes is a knowledge management solution that connects employees with the knowledge they need when they need it… including how to save on healthcare expenses using Health Cost Labs. Through the <a href="https://business.omeganotes.com/main_app?community=1666977935429x786726420295974900&nav=section" target="_blank">Health Cost Labs Alpha Community</a> on Omega Notes, employees can get their questions answered on how to shop for healthcare, get estimates, hear purchasing stories and get the best care at the lowest price.<br></br>Omega Notes believes a knowledgeable employee is a safer, healthier, and more motivated employee. To learn how you can further engage and align your workforce through knowledge sharing and transparency, visit Omega Notes.”</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>




                    <div className="mt-3 pt-3 home-about-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    {/* <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/jX3aHRNAkPs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe> */}
                                    {/* <video
                                        width="100%"
                                        height="315"
                                        poster="https://mms.businesswire.com/media/20210426005489/en/873778/5/high-res.jpg"
                                        controls className="home-video"
                                    >
                                        <source
                                            src="https://mms.businesswire.com/media/20210426005489/en/873778/19/5005979_yt1s.com_-_Power_to_the_Patients_Prices_are_Now_a_Patients_Right_1080p_1.mp4&.mp4?download=1"
                                            type="video/mp4"
                                        />
                                        <source
                                            src="movie.ogg"
                                            type="video/ogg"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video> */}
                              <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/miiyJ5cU-kw?si=i2w7q_VkGqvXV7-J" 
                                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                                </iframe> 


                                </div>
                                <div className="col-md-6">
                                    <p className="bold_text_no_text_transform text-center">
                                        {/* Public Service Announcement airs during
                                        Oscars, featuring */}
                                        Please watch this video on how to use 
                                    </p>
                                    <p className="bold_text_no_text_transform text-center">
                                        {/* Susan Sarandon & Cynthia Erivo,
                                        proclaiming Prices are */}
                                        the price transparency data to dispute your medical bills.
                                    </p>
                                    {/* <p className="bold_text_no_text_transform text-center">
                                        now a Patient’s Right
                                    </p> */}
                                    <p className="card-text text-justify pl-5">
                                        {/* Activist and Academy-Award winning
                                        actress Susan Sarandon and Grammy, Emmy
                                        and Tony Award-Winning and Academy-
                                        Award nominated actress Cynthia Erivo
                                        starred in a new public service
                                        announcement (PSA) during the Oscars
                                        titled “Power to the Patients” which
                                        focuses on increasing awareness that
                                        hospital prices are now a patient’s
                                        right prior to receiving care. */}
                                        <a href="https://en.wikipedia.org/wiki/Uniform_Commercial_Code"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#e8ac4c", fontWeight: "bold", textDecoration: "underline"}}>
                                        The Uniform Commercial Code (UCC) is a comprehensive set of laws governing all commercial transactions in the United States.
                                        </a>&nbsp;Within these laws is a law called Open Price Term. 
                                        An open price term is a contract for sale in which nothing is said as to price, 
                                        the price is to be agreed later by parties, the price is to be fixed by a market standard or by a third party, 
                                        or the price is to be fixed in good faith by the seller or buyer.
                                    </p>
                                    <p className="card-text text-justify pl-5" >
                                    *This is not legal advice. Consult your attorney if the hospital does not accept your price for payment in full.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-bg-light-gray mt-5 pt-3">
                        <div className="container pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="bold_text_no_text_transform font20">
                                        Hospital Rates are now Public
                                    </h3>
                                    <p>
                                        Health Cost Labs informs you of lower
                                        cost alternatives using billions of
                                        rates grouped by:
                                    </p>
                                    <p>
                                        —&gt; MSA Regions
                                        <br />
                                        —&gt; Carriers
                                        <br />
                                        —&gt; Hospitals
                                        <br />
                                        —&gt; Products
                                    </p>
                                    <p>We offer Rates for use by:</p>
                                    <p>
                                        —&gt; Employers
                                        <br />
                                        —&gt; Health Plans
                                        <br />
                                        —&gt; Hospitals & Doctors
                                        <br />
                                        —&gt; Consumers
                                        <br />
                                        —&gt; Benefit Companies
                                    </p>
                                    <p>
                                        We can support all segments with
                                        analytics, benchmark and cost savings
                                        opportunities:
                                    </p>
                                    <p>
                                        —&gt; Benchmarking: Medicare & Peer
                                        review
                                        <br />
                                        —&gt; Network Optimization and Cost
                                        Savings
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="bold_text_no_text_transform text-center pt-3 font20">
                                        <u className="underline-text">
                                            STATE OF ALASKA
                                        </u>
                                    </h3>
                                    <div className="card card_border_padding">
                                        <img
                                            src="images/Hospital image 3.jpg"
                                            className="card-img-top"
                                            alt="..."
                                        ></img>
                                        <div className="card-body text-center">
                                            <Link
                                                to="/pivotTable"
                                                className="btn btn-orange tag-btn"
                                            >
                                                <img
                                                    className="pr-2"
                                                    src="images/view-icon.png"
                                                />
                                                View Files
                                                <span className="badge rounded-pill badge-notification">
                                                    Free
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Home;
