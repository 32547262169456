import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";
import AutoRenew from "../subcomponents/AutoRenew";

class Profile extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        if (!token) {
            this.props.history.push("/login");
        }

        this.state = {
            token: localStorage.getItem("token"),
            users: [],
            subscriptionDetails: [],
            uploadSucess: false,
            editUploadisValid: false,
            uploadFile: "",
            errorMessage: "",
            count: 0

        };

        this.convertDateTime = this.convertDateTime.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.alertChange = this.alertChange.bind(this)
        this.onInputClick = this.onInputClick.bind(this);
    }

    refreshToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const expired = localStorage.getItem("expired");

        if (token !== null && refreshToken !== null && expired !== null) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
                    {
                        token: token,
                        refreshToken: refreshToken,
                        expiration: expired,
                    }
                )
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expiration);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        // localStorage.removeItem("token");
                        // localStorage.removeItem("email");
                        // localStorage.removeItem("userID");
                        // localStorage.removeItem("userName");
                        // localStorage.removeItem("secret_key");
                        // localStorage.removeItem("refreshToken");
                        // localStorage.removeItem("expired");
                        this.props.history.push("/logout");
                    }
                });
        }
    }

    getUserDetails() {
        var queryString = JSON.stringify({
            Email: localStorage.getItem("email"),
        });

        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/user/getUserDetails?UserInput=${queryString}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    users: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/logout");
                }
            });
    }
    getUserSubscriptionDetails() {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/subscription/getsubscription`,

                {
                    userID: localStorage.getItem("userID"),
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    subscriptionDetails: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/logout");
                }
            });
    }

    handleFileChange(event) {
        console.log(event)
        if (event.target.files[0] !== undefined) {

            const fext = event.target.files[0].name.split('.').pop();
            if (fext === "edi") {
                this.setState({
                    uploadFile: event.target.files[0],
                    uploadSucess: false,
                    errorMessage: '',
                });

            }
            else {
                // this.state.errorMessage
                // console.log("error")
                this.setState({
                    errorMessage: "Please select .edi files only",
                    uploadSucess: false,
                    uploadFile: ""
                });
            }

        }

    }

    // generate UUID
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    handleSubmit(event) {
        const {
            uploadFile,
        } = this.state;

        if (uploadFile !== "") {
            const formData = new FormData();
            const fname = this.state.uploadFile.name.split('.').slice(0, -1).join('.');
            const uid = this.uuidv4();
            formData.append("File", this.state.uploadFile, `${fname}_${uid}.edi`);
            formData.append("userid", localStorage.getItem("userID"));
            formData.append("OriginalFileName", this.state.uploadFile.name);
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/user/uploadedifile`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.state.token}`,
                            secret_key: localStorage.getItem("secret_key"),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    if (response.data.isSuccess !== false) {
                        this.setState({
                            fileUrl: response.data.message,
                            isLoaded: true,
                            uploadFile: '',
                            uploadSucess: true,
                            errorMessage: '',
                            count: 0
                        });
                    } else {
                        this.setState({
                            errorMessage: response.data.message,
                            uploadSucess: response.data.isSuccess
                        });
                    }


                    // console.log(response,this.state.uploadSucess);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        // localStorage.removeItem("token");
                        // localStorage.removeItem("email");
                        // localStorage.removeItem("userID");
                        // localStorage.removeItem("userName");
                        // localStorage.removeItem("secret_key");
                        // localStorage.removeItem("refreshToken");
                        // localStorage.removeItem("expired");
                        this.props.history.push("/logout");
                    }
                    this.setState({
                        errorMessage: error.message,
                        uploadSucess: false
                    });
                });
        }

        event.preventDefault();
    }

    alertChange() {
        this.setState({
            uploadSucess: false,
            errorMessage: ""
        });
    }

    componentDidMount() {
        this.refreshToken();
        this.getUserDetails();
        this.getUserSubscriptionDetails();
    }

    componentDidUpdate() {
        // if has subscription then only this script work. Its for Edi datatable .
        if (this.state.count === 0 && this.state.subscriptionDetails.length > 0 || this.state.count === undefined && this.state.subscriptionDetails.length > 0) {
            this.setState({
                count: this.state.count + 1
            });
            const script = document.createElement("script");
            script.async = true;
            script.src = "/js/profilecustom.js";
            this.div.appendChild(script);
            const uid = window.location.hash;
            console.log(document.body.scrollHeight)
            if (uid === '#uploadEdi') {

                setTimeout(
                    function () {
                        window.scrollTo({
                            top: document.body.scrollHeight,
                            left: 0,
                            behavior: 'smooth'
                        });
                    },
                    550
                );


            }

        }



    }

    convertDateTime(fullDate) {
        if (fullDate !== null) {
            var timestamp = Date.parse(fullDate);
            var newdate = new Date(timestamp);
            var dateString =
                newdate.getDate() +
                " " +
                newdate.toLocaleString("default", { month: "long" }) +
                " " +
                newdate.getFullYear() +
                " " +
                newdate.getHours() +
                ":" +
                newdate.getMinutes() +
                ":" +
                newdate.getSeconds();

            return dateString;
        } else {
            return "";
        }
    }
    convertDateTimeForListing(fullDate) {
        if (fullDate !== null) {
            var timestamp = Date.parse(fullDate);
            var newdate = new Date(timestamp);
            var dateString =
                newdate.getDate() +
                " " +
                newdate.toLocaleString("default", { month: "long" }) +
                " " +
                newdate.getFullYear() +
                " ";

            return dateString;
        } else {
            return "";
        }
    }

    onInputClick(event) {
        event.target.value = '';
        this.setState({
            uploadFile: "",
            uploadSucess: false,
            errorMessage: ""
        })
    }

    render() {
        const { users, subscriptionDetails } = this.state;
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="container">
                        <div className="row justify-content-center" ref={(el) => (this.div = el)}>
                            {users.map((user, index) => (
                                <div
                                    className="col-md-12 col-lg-12"
                                    key={index}
                                >
                                    <h3 className="text-center font-weight-bold mb-4 profile-welcome">
                                        <span className="profile-wlcome-text">Welcome</span>
                                        {localStorage.getItem("userName")}

                                        {/* {user.emailID} &nbsp; */}
                                        {user.emailConfirmed && (
                                            <span className="text-success">
                                                (Verified)
                                            </span>
                                        )}
                                        {user.emailConfirmed ===
                                            false && (
                                                <><br></br><span className="text-danger">
                                                    (Email address is
                                                    not verified.)&nbsp;
                                                    <Link
                                                        to="/verifyEmail"
                                                        className="text-success"
                                                    >
                                                        Verify Now
                                                    </Link>
                                                </span>
                                                </>
                                            )}

                                    </h3>
                                    {/* <p>
                                        Last Login:&nbsp;
                                        {this.convertDateTime(user.lastLogin)}
                                    </p> */}

                                    {/* <div className="card roundborder mb-5">
                                        <div className="card-body"> */}
                                    {/* <div className="row mb-2 float-right">
                                                <h5>
                                                    <small>
                                                        <i
                                                            className="fa fa-pencil-square-o"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </small>
                                                </h5>
                                            </div> */}
                                    {/* <div className="row mb-2">
                                                <div className="col-md-3 text-muted">
                                                    First Name :
                                                </div>
                                                <div className="col-md-9">
                                                    {user.firstName}
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 text-muted">
                                                    Last Name :
                                                </div>
                                                <div className="col-md-9">
                                                    {user.lastName}
                                                </div>
                                            </div> */}
                                    {/* <div className="row mb-2">
                                                <div className="col-md-1 text-muted">
                                                    Email:
                                                </div>
                                                <div className="col-md-9">
                                                    {user.emailID} &nbsp;
                                                    {user.emailConfirmed && (
                                                        <span className="text-success">
                                                            (Verified)
                                                        </span>
                                                    )}
                                                    {user.emailConfirmed ===
                                                        false && (
                                                            <span className="text-danger">
                                                                (Email address is
                                                                not verified.)&nbsp;
                                                                <Link
                                                                    to="/verifyEmail"
                                                                    className="text-success"
                                                                >
                                                                    Verify Now
                                                                </Link>
                                                            </span>
                                                        )}
                                                </div> */}
                                    {/* {user.emailConfirmed === false && (
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <p>
                              Email Address is not verified.
                              <Link
                                to="/verifyEmail"
                                className="btn btn-success pull-right"
                              >
                                Verify Now
                              </Link>
                            </p>
                          </div>
                        )} */}
                                    {/* </div>
                                        </div>
                                    </div> */}
                                </div>
                            ))}
                            <div className="col-md-12 mb-5">
                                {" "}
                                <h3 className="text-center font-weight-bold mb-3">
                                    Active Subscriptions
                                </h3>
                                <div className="row rowborder m-0 pb-0 justify-content-center">
                                    {
                                        this.state.subscriptionDetails.length > 0 ?
                                            <>
                                                {subscriptionDetails.map(
                                                    (subscription, index) => (
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={index}>
                                                            <div className="card border border-warning roundborder h-100 my-subscriptions">
                                                                <div className="card-body position-relative">
                                                                    {
                                                                        subscription.state ? <h5 className="card-title text-orange font-weight-bold text-center">
                                                                            {subscription.state}
                                                                        </h5> : ''
                                                                    }

                                                                    {
                                                                        subscription.individualSearch ? <h5 className="card-title text-orange font-weight-bold text-center">
                                                                            Individual Search
                                                                        </h5> : ''
                                                                    }

                                                                    <p className="card-text">
                                                                        <div className="font-weight-bold text-center pb-3">
                                                                            {
                                                                                subscription.subscriptiontype
                                                                            }
                                                                        </div>
                                                                        <div className="text-center">

                                                                            Expiration Date:
                                                                             {this.convertDateTimeForListing(
                                                                                subscription.expireDate
                                                                            )}
                                                                        </div>
                                                                        {
                                                                            subscription.individualSearch ?
                                                                                <>
                                                                                    <div className="text-center">

                                                                                        Total Search: {subscription.totalSearchCount}
                                                                                    </div>
                                                                                    <div className="text-center">

                                                                                    Remaining Search: {subscription.remaningSearchCount}
                                                                                    </div>
                                                                                </> : ''
                                                                        }


                                                                    </p>
                                                                    <div className="auto-renewal">
                                                                        <h6>Auto Renewal: </h6> <AutoRenew subscid={subscription.mySubscriptionID} isactive={subscription.autoRenewal} state={subscription.state} subscriptiontype={subscription.subscriptiontype} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}</> :
                                            <div className="col-12 mb-4 no-subscription text-center text-danger">
                                                You dont have any Subscription
                                            </div>
                                    }

                                </div>
                            </div>



                            {/* {
                                this.state.subscriptionDetails.length > 0 ?
                                    <>
                                        <div className="col-md-12 mb-5">

                                            <h3 className="text-center font-weight-bold mb-3">
                                                Donated EDI Files Status
                                            </h3>
                                            <div className="row rowborder m-0">
                                                <div className="col-12">
                                                    <table className="table table-donated-edi table-borderless  table-blue table table-striped table-bordered table-sm mb-0" id="tbl_donated_edi">
                                                        <thead>
                                                            <tr>
                                                                <th>File Name</th>
                                                                <th>Upload Date</th>
                                                                <th>Status</th>
                                                                <th>Processed On</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-5" id="uploadEdi">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6 text-center">
                                                    <h4 className="font-weight-bold ">
                                                        EDI Files
                                                    </h4>
                                                    <p>
                                                        <em>Donate EDI Files to get discount rates on Drug Data</em>
                                                    </p>
                                                    {
                                                        this.state.uploadSucess? <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                        File Upload Successfully
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div> :
                                                        ''
                                                    }
                                                    {
                                                        this.state.errorMessage? <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                        {this.state.errorMessage}
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div> :
                                                        ''
                                                    }
                                                    <form onSubmit={this.handleSubmit}  >
                                                        <label
                                                            for="fileUpload"
                                                            className="file-upload btn bg-orange btn-block rounded-pill shadow text-white w-50  ml-auto mr-auto mb-4"
                                                        >
                                                            Upload
                                                            <i className="fa fa-upload ml-2"></i>
                                                            <input
                                                                id="fileUpload"
                                                                type="file"
                                                                name="uploadFile"
                                                                onChange={this.handleFileChange}
                                                                accept=".edi"
                                                                onClick={this.onInputClick}
                                                            />
                                                        </label>
                                                        <div className="form-group">
                                                            <small className="mb-3">{this.state.uploadFile.name}</small>
                                                        </div>
                                                        <div className="form-group" >
                                                            <button
                                                                type="submit"
                                                                disabled={this.state.uploadFile == ""}
                                                                className="btn btn-orange mb-2"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ''
                            } */}

                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Profile);
